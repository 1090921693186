.btn-primary {
  background-color: #037ef2;
  border-radius: 16px;
}
.badge-vps {
  background-color: rgba(248, 90, 64, 0.15) !important;
  color: #f85a40;
}

.loader {
  padding: 10%;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
//Opportunity List Styling
.oppList {
  padding: 2%;
  .OppsTable {
    text-align: center;
    font-family: "Poppins", sans-serif;
    border: 2px solid #e8ebed;
    border-radius: 10px;
    border-collapse: separate;
    border-spacing: 0;
    th {
      color: #037ef2;
      padding: 1% 5px;
      background-color: rgba(3, 126, 242, 0.1);
    }
    .oppProv {
      color: #037ef2;
    }
    .grayCol {
      color: #a098ae;
    }
  }
}
//Opportunity Details Styling
.oppDetails,
.positionDetails,
.manageOpps,
.addOpp {
  border: 2px solid #e8ebed;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 3% 2%;
  padding: 5%;
  .applicantsNumber {
    margin-top: 1%;
    color: #a098ae;
  }
  .badge-container * {
    margin-top: 20%;
  }
  .shareOn {
    color: #037ef2;
  }
  .oppName {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 32px;
  }

  .oppDuration {
    padding: 1% 2%;
    min-height: 60px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    color: #037ef2;
    gap: 12px;
    background-color: rgba(3, 126, 242, 0.1);
    margin: 1% 0%;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 20px;
  }
  .appDate {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 15px;
    border: solid #e8ebed 2px;
    border-radius: 8px;
    padding: 1% 2%;
    .deadline {
      color: #df2e2e;
    }
  }
  .OppsTableDetails,
  .ApplicationsTableDetails {
    margin-top: 56px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    border: 2px solid #e8ebed;
    border-radius: 10px;
    border-collapse: separate;
    border-spacing: 0;
    th {
      color: #037ef2;
      padding: 1% 5px;
      background-color: rgba(3, 126, 242, 0.1);
      border: 0px;
    }
    td {
      border: 0px;
      padding: 20px;
    }
    .oppProv {
      color: #037ef2;
    }
    .grayCol {
      color: #a098ae;
    }
  }
  //Position Details Styling
  .textBlock {
    margin-top: 32px;
    h1 {
      margin-top: 16px;
      font-family: Poppins, sans-serif;
      font-weight: 500;
      font-size: 24px;
    }
  }
  .applyButton {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 16px;
    float: right;
    padding: 16px 64px;
  }
}
//Manage Opportunity Styling
.status-stats {
  p {
    font-family: Poppins, sans-serif;
    margin-top: 15px;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 16px;
  }
  padding-top: 40px;
}
.ApplicationsTableDetails {
  font-family: "Poppins", sans-serif;
  .id {
    font-weight: 600;
    color: #303972;
  }
  .name {
    font-weight: 600;
    color: #037ef2;
  }
  .date {
    font-weight: 400;
    color: #a098ae;
  }
}
//Add Opportunities Styling
.addOpp {
  .addTitle {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 40px;
  }
  .addPosition {
    margin-top: 15px;
    border: 2px solid #e8ebed;
    border-radius: 10px;
    background-color: #ffffff;
  }
  .posTitle {
    margin-top: 20px;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-weight: 500;
  }
  .form-label,
  .addPosBtn {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    margin-top: 30px;
  }
  .removePosBtn {
    margin-bottom: 20px;
  }
  .addPosBtn {
    background-color: transparent !important;
    color: black;
    border: none;
    margin-bottom: 20px;
  }
}
