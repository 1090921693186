.loginPage {
  background-color: #037ef2;
  height: 100vh;
  .wave {
    width: 96px;
    height: 96px;
  }
  .welcomeBox {
    text-align: center;
    padding: 50px;
    background-color: #ffffff;
    position: absolute;
    margin-top: 7%;
    margin-left: 30%;
    border-radius: 32px;
    margin-right: auto;
    width: 90vh;
    height: 70vh;
    font-family: "Lato";
    .AiesecIcon {
      width: 30px;
    }
    .loginbtn {
      padding: 10px 20%;
      font-family: "Poppins";
      font-size: 22px;
      background-color: #037ef2;
    }
    * {
      margin: 0;
    }
    img {
      margin-bottom: 2%;
    }
    h1 {
      font-weight: bolder;
      font-size: 60px;
      margin-bottom: 50px;
    }
    h2 {
      font-size: 32px;
    }
  }
}
