//Main Page css
.spinner {
  margin: 20% 45%;
}
.profilePage {
  background-color: #ffffff;
  border: solid 1px #e8ebed;
  margin: 3%;
  border-radius: 8px;
}
//Tab Content
.profileContent {
  padding: 0 2%;
  .tabList {
    font-weight: 700;
    color: rgba(125, 125, 125, 0.49);
  }
  .tablist-selected {
    border-bottom: solid #037ef2 2px;
    color: #037ef2;
  }
  .profileDesc {
    .profileParagraph {
      margin-top: 5%;
    }
    .infoData * {
      margin-left: 5px;
    }
    .title {
      color: black;
      font-weight: 500;
    }
    padding: 2% 3%;
    font-weight: 400;
    font-family: Poppins, sans-serif;
  }
}
//Timeline
.applicationTimeline {
  padding: 20px;
}

.application-timeline {
  margin-bottom: 40px;

  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #037ef2;
    font-weight: 400;

    span {
      color: #037ef2;
      font-weight: 700;
    }
  }

  .timeline {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 20px;
    height: 100px;

    &::before {
      content: "";
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #dcdcdc;
      z-index: 1;
    }

    .timeline-item {
      position: relative;
      text-align: center;
      font-family: Poppins, sans-serif;
      .timeline-content {
        padding: 10px 20px;
        border-radius: 5px;
        margin-bottom: 20px;
        z-index: 3;
        p {
          margin: 0;
          font-weight: 500;
        }

        &.applied {
          background-color: #e3f2fd;
          color: #1e88e5;
        }

        &.accepted {
          background-color: #fff3e0;
          color: #ff9800;
        }

        &.approved {
          background-color: #e8f5e9;
          color: #66bb6a;
        }

        &.realised {
          background-color: #e8f5e9;
          color: #81c784;
        }

        &.finished {
          background-color: #e8f5e9;
          color: #a5d6a7;
        }
      }

      .timeline-dot {
        width: 10px;
        height: 10px;
        background-color: #007bff;
        border-radius: 50%;
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }

      .timeline-date {
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
        color: #666;
      }
    }
  }
}

//Profile Info (Top part)
.profInfo {
  text-transform: capitalize;
  padding: 2%;
  img {
    max-width: 100%;

    border-radius: 50%;
  }
  font-family: Poppins, sans-serif;
  font-weight: 400;
  color: #7d7d7d;
  .name {
    font-weight: 600;
    color: black;
  }
  .currentPos {
    text-align: center;
    color: #037ef2;
    background-color: rgba(3, 126, 242, 0.1);
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
  }
}
