.dashboard {
  .filter {
    border-radius: 10px;
    background-color: #ffffff;
    margin: 3% 2%;
    padding: 1% 2%;
    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.05);
    .title {
      font-family: Lato, sans-serif;
      font-weight: bolder;
      font-size: 24px;
      color: #037ef2;
    }
  }
  .stats {
    justify-content: space-between;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 3% 2%;
    padding: 1% 3%;
    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.05);
    display: flex;
    gap: 50px;
  }
  .statBlock {
    display: flex;
  }
  .statValue,
  .statTitle {
    text-align: center;
    font-family: Public Sans, sans-serif;
    font-size: 14px;
  }
  .statValue {
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .statLine {
    margin-top: 15px;
  }
  .statTitle {
    color: #64748b;
  }
  .chartBlock {
    display: flex;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 3% 2%;
    padding: 1% 2%;
    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.05);
    .titleSection {
      display: flex;
      flex-direction: column;
      align-items: center; /* Center text horizontally */
      justify-content: center; /* Center text vertically */
      text-align: center;

      flex: 0.2;
      .chartTitle {
        font-family: Lato, sans-serif;
        font-weight: 700;
        font-size: 24px;
        color: #303972;
      }
      .chartSubTitle {
        font-family: Lato, sans-serif;
        font-weight: 400;
        font-size: 12px;
        color: #7d7d7d;
      }
    }
    .chartSection {
      flex: 0.8;
    }
  }
}
