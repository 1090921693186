//complaint List Styling
.complaintList {
  .btn-primary {
    background-color: #037ef2;
    border-radius: 8px;
    padding: 4% 20%;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  .search {
    border-radius: 10px;
    background-color: #ffffff;
    margin: 3% 2%;
    padding: 1% 2%;
    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.05);
  }
  .complaintCards {
    margin: 3% 2%;
    font-family: "Poppins", sans-serif;
    .complaintCard {
      padding: 2%;
      margin: 2% 1%;
      width: 23vw;
      box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      background-color: #ffffff;
      .complaintTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
      }
      .complaintDesc {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: #64748b;
      }
      .complaintDeadline {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;

        line-height: 17px;
        float: right;
        color: #df2e2e;
      }
      .fillNow {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #037ef2;
        a {
          text-decoration: none;
        }
      }
    }
  }
}
//complaint Details Listing
.survDetails {
  border: 2px solid #e8ebed;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 3% 2%;
  padding: 5%;
  .applicantsNumber {
    margin-top: 1%;
    color: #a098ae;
  }
  .badge-container * {
    margin-top: 20%;
  }
  .shareOn {
    color: #037ef2;
  }
  .survName {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 32px;
  }
  .survDuration {
    min-height: 60px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    color: #037ef2;
    gap: 12px;
    background-color: rgba(3, 126, 242, 0.1);
    margin: 1% 0%;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 20px;
  }
  .appDate {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 15px;
    border: solid #e8ebed 2px;
    border-radius: 8px;
    padding: 2px 16px;
    .deadline {
      color: #df2e2e;
    }
  }
  .OppsTableDetails,
  .ApplicationsTableDetails {
    margin-top: 56px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    border: 2px solid #e8ebed;
    border-radius: 10px;
    border-collapse: separate;
    border-spacing: 0;
    th {
      color: #037ef2;
      padding: 1% 5px;
      background-color: rgba(3, 126, 242, 0.1);
      border: 0px;
    }
    td {
      border: 0px;
      padding: 20px;
    }
    .oppProv {
      color: #037ef2;
    }
    .grayCol {
      color: #a098ae;
    }
  }
  //Position Details Styling
  .textBlock {
    margin-top: 32px;
    h1 {
      margin-top: 16px;
      font-family: Poppins, sans-serif;
      font-weight: 500;
      font-size: 24px;
    }
  }
  .applyButton {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 16px;
    float: right;
    padding: 16px 64px;
  }
}
.addSurv {
  border: 2px solid #e8ebed;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 3% 2%;
  padding: 5%;
  .addTitle {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 40px;
  }
  .addPosition {
    margin-top: 15px;
    border: 2px solid #e8ebed;
    border-radius: 10px;
    background-color: #ffffff;
  }
  .posTitle {
    margin-top: 20px;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-weight: 500;
  }
  .form-label,
  .addPosBtn {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    margin-top: 30px;
  }
  .removePosBtn {
    margin-bottom: 20px;
  }
  .addPosBtn {
    background-color: transparent !important;
    color: black;
    border: none;
    margin-bottom: 20px;
  }
  .posSelect {
    margin-top: 5%;
  }
}
