.sidebar-title-container {
  background-color: #ffffff;
  padding: 1% 0;
  display: flex;
  align-items: center;
}
.dropdown-toggle::after {
  display: none !important;
}
.loginBtn {
  padding: 10px 30px !important;
  font-family: Poppins, sans-serif !important;
  font-weight: 600 !important;
  .AiesecIcon {
    width: 20px;
  }
}

.topOverlay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;

  .sidebar-title-container {
    display: flex;
    align-items: center;

    .sidebar-title {
      font-size: 24px;
      color: #037ef2;
      margin-right: 10px;
      font-weight: 400;
    }
  }

  .profile {
    display: flex;
    align-items: center;
    .profilepic {
      width: 33px;
      border-radius: 10%;
    }
    .btn-dark {
      background-color: #f5f5fb !important;
      border: none;
      border-radius: 16px;
    }
    .profile-button {
      padding: 10px;
      background-color: #f5f5fb;
      border: none;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33px;
      height: 33px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.content {
  display: flex;
  min-height: 100vh;
}

.sidebar-title {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #037ef2;
  margin-left: 43px;
  margin-right: 32px;
}

.redirect {
  background-color: #037ef2;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
